.unit-title-v1 {


	.stars {
		display: inline-block;
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;

		.ratings {
			display: flex;
		}
	}
}